import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import ReadLink from "../components/read-link"

const ProjectPreview = ({ project }) => {
  return (
    <article
      css={css`
        padding: 0 0.5rem;

        flex: 0 0 100%;
        
        @media (min-width:600px) {
          flex: 0 0 50%;
        }
        @media (min-width:900px) {
          flex: 0 0 33.3%;
        }
        margin: 0 0 1rem;
        picture, img {
          max-width: 100%;
          height:auto;
          padding:0;
          margin: 0;
        }
      `}
    >
      <Link
        to={project.slug}
        css={css`
          margin: 1rem 1rem 0 0;
          width: 100px;
          flex: 0 0 100px;
        `}
      >
        <picture>
          <source srcSet={project.image.fixed.srcSetWebp} type="image/webp" />
          <source srcSet={project.image.fixed.srcSet} type="image/png" />
          <img
            loading="lazy"
            src={project.image.fixed.src}
            title={project.image.title}
            alt={project.image.description}
            height={project.image.fixed.height}
            width={project.image.fixed.width}
          />
        </picture>
      </Link>
      <div>
        <h3>
          <Link to={project.slug}>{project.title}</Link>
        </h3>
        <p>{project.subtitle}</p>
        <ReadLink to={project.slug}>read more &rarr;</ReadLink>
      </div>
    </article>
  )
}

export default ProjectPreview




