import { graphql, useStaticQuery } from "gatsby"

const useLatestTV = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/tv/" } }
        sort: { order: DESC, fields: frontmatter___date}
        limit: 1) {
        nodes {
          frontmatter {
            title
            slug
            date
            subtitle
            image {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
  `)

  return data.allMdx.nodes.map(post => ({
    title: post.frontmatter.title,
    subtitle: post.frontmatter.subtitle,
    slug: `tv/` + post.frontmatter.slug,
    image: post.frontmatter.image,
    date: post.frontmatter.date
  }))
}

export default useLatestTV
