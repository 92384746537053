import React from "react"
import Layout from "../components/Layout"

import { css } from "@emotion/core"
import PostPreview from "../components/post-preview"
import usePosts from "../hooks/use-posts"

import useProjects from "../hooks/use-projects"
import ProjectPreview from "../components/project-preview"

import Media from "../components/Media"

export default function Home() {
  const posts = usePosts()
  const projects = useProjects({featured:true})
  
  return (
    <>
      <Layout>
        <div>
          <div
            css={css`
              p {
                font-size: clamp(1rem, 1rem + 2vw, 2.2rem);
                
                font-weight: 300;
              }
              padding: 1rem 0 3rem;
              border-bottom: 1px solid #e2e2e2;
              margin-bottom: 1rem;
            `}
          >
            <p>Hi! I'm Matt</p>
            <p>I'm a Freelance <b>Front End Developer</b> based in Adelaide.</p>
            <p>
              I <b>make things</b> at <a href="http://emptyhead.com.au">Emptyhead&nbsp;Digital</a> and <b>consult</b> for the wonderful teams at <a href="https://outofthesquare.com.au">Out&nbsp;of&nbsp;the&nbsp;Square</a>, <a href="https://www.studiojack.com.au/">Studio&nbsp;Jack</a> and <a href="https://acmn.com.au">ACMN</a>.
            </p>
          </div>
          
          <h2
        css={css`
          text-align: center;
          margin: 2em 0 1em;
          text-transform: uppercase;
        `}
      >RECENT WORK:</h2>
          <div
            css={css`
              margin: 1rem -0.5rem;
              display: flex;
              flex-wrap: wrap;
              
            `}
          >
            {projects.map(project => (
              <ProjectPreview key={project.id} project={project}></ProjectPreview>
            ))}
          </div>

          <h2
        css={css`
          text-align: center;
          margin: 2em 0 1em;
          text-transform: uppercase;
        `}
      >Latests Blog:</h2>
          {posts.map(post => (
            <PostPreview key={post.slug} post={post}></PostPreview>
          ))}
          <Media />
        </div>
      </Layout>
    </>
  )
}
