import { graphql, useStaticQuery } from "gatsby"

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/posts/" } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            slug
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 300, height: 300)
              }
            }
          }
          excerpt
        }
      }
    }
  `)

  return data.allMdx.nodes.map(post => ({
    title: post.frontmatter.title,
    slug: `posts/` + post.frontmatter.slug,
    image: post.frontmatter.image,
    excerpt: post.excerpt,
  }))
}

export default usePosts
