import { graphql, useStaticQuery } from "gatsby"

const useLatestMovies = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/movies/"} }
        sort: { order: DESC, fields: frontmatter___date}
        limit: 1) {
          nodes {
            frontmatter {
              title
              slug
              date
              subtitle
              image {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
            }
          }  
        }
    }
  `)

  return data.allMdx.nodes.map(post => ({
    title: post.frontmatter.title,
    subtitle: post.frontmatter.subtitle,
    slug: `movies/` + post.frontmatter.slug,
    image: post.frontmatter.image,
    date: post.frontmatter.date
  }))
}

export default useLatestMovies
