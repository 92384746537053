import React from 'react'
import { Link } from "gatsby"
import { css } from "@emotion/core"


import MediaStub from './MediaStub'
import useLatestBooks from '../hooks/use-latest-book'
import useLatestTV from '../hooks/use-latest-tv'
import useLatestMovies from '../hooks/use-latest-movies'


// import useBooks from '../hooks/use-latest-book'

export default function Media(){
  const books = useLatestBooks()
  const tvs = useLatestTV();
  const movies = useLatestMovies();
  return (
    <>
      <h2
        css={css`
          text-align: center;
          margin: 2em 0 1em;
          text-transform: uppercase;
        `}
      >Stuff I'm Enjoying</h2>
      <div css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
  
        justify-content: space-between;
        > div {
          flex: 1;
          padding: 0.5rem 1rem 0;
          background: #6B87A8;
          color: white;
          border-radius: 5px;
          h3, h4 {
            color: white;
          }
          h4 {
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid #eee;
          }
          a {
            color: white;
            display: block;
            margin: 0.5rem 0 1em;
            text-decoration: none;
            &:visited {
                color: white;
            }
            &:hover {
              color: tomato;
            }
          }
        }
      `}>
        <div>
          <h4>Latest Book:</h4>
          {books.map(book => (
            <MediaStub media={book} dateText="Finished" /> 
          ))}
          <Link to="/books"><small>More Books &rarr;</small></Link>
        </div>
        <div>
          <h4>Latest TV:</h4>
          {tvs.map(tv => (
            <MediaStub media={tv} dateText="Latest Episode" /> 
          ))}
          <Link to="/tv"><small>More TV &rarr;</small></Link>
        </div>
        <div>
          <h4>Latest Movie:</h4>
          {movies.map(movie => (
            <MediaStub media={movie} dateText="Watched" /> 
          ))}
          <Link to="/movies"><small>More Movies &rarr;</small></Link>
        </div>
      </div>
    </>
  )
}