import { graphql, useStaticQuery } from "gatsby"

const useProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProjects(filter: {showOnMattEdwards: {eq: true}}, sort: {order: DESC, fields: createdAt}) {
        nodes {
          id
          title
          subtitle
          url
          slug
          description {
            raw
          }
          featuredImage {
            fixed(height: 400, width: 600, cropFocus: TOP) {
              srcSetWebp
              srcSet
              src
              height
              width
            }
            title
            description
          }
        }
      }
    }
  `)

  return data.allContentfulProjects.nodes.map(project => ({
    id: project.id,
    title: project.title,
    subtitle: project.subtitle,
    slug: `project/` + project.slug,
    url: project.url,
    description: project.description.raw,
    image: project.featuredImage,
  }))
}

export default useProjects